import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useMulticallContract ,useTokenContract} from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/eggPriceContracts'

type ApiResponse = {
  prices: {
    [key: string]: string
  }
  update_at: string
}

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
const api = 'https://api.pancakeswap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)
  const {cakeAddress, busdAddress, lpAddress} = priceContracts;

  const cakeToken  = useTokenContract(cakeAddress)!;
  const busdToken  = useTokenContract(busdAddress)!;

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){
          // const calls = [
          //   [cakeAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          //   [busdAddress, ERC20_INTERFACE.encodeFunctionData("balanceOf", [lpAddress])],
          // ];

          // const [ result] = await multicallContract.aggregate(calls);
          // console.log("result",result)
          
          const cakeAmount = await cakeToken.balanceOf(lpAddress);
          const busdAmount = await busdToken.balanceOf(lpAddress);

          const cake =  new BigNumber(cakeAmount._hex).div(new BigNumber(10).pow(18));
          const busd =new BigNumber(busdAmount._hex).div(new BigNumber(10).pow(6));
          const cakePrice = busd.div(cake).toNumber();
        
          setData(cakePrice)

          console.log("ggfgffgfgfg",cakeAmount)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

   setInterval(()=>{
    fetchData()
   },4000)
   fetchData()
  }, [multicallContract,cakeToken,busdToken,lpAddress,cakeAddress, busdAddress])

  return data
}

export default useGetPriceData
